import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Servicios from "../../components/services/servicios_seguridad"

const Ciberseguridad = () => {
  const data = useStaticQuery(graphql`
    query imagesCiberseguridad {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Empresa especialista en Ciberseguridad | Qualoom</title>
        <meta name="title" content="Empresa especialista en Ciberseguridad | Qualoom"/>
        <meta name="description" content="Expertos en Ciberseguridad, Análisis y Diagnóstico de Aplicaciones y Arquitecturas y Monitorización de ecosistemas digitales E-Commerce, CRM y ERPs."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/seguridad-informatica/ciberseguridad/"/>
        <link rel="canonical" href="https://www.qualoom.es/seguridad-informatica/ciberseguridad/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/seguridad-informatica/ciberseguridad/"/>
        <meta property="og:title" content="Empresa especialista en Ciberseguridad | Qualoom"/>
        <meta property="og:description" content="Expertos en Ciberseguridad, Análisis y Diagnóstico de Aplicaciones y Arquitecturas y Monitorización de ecosistemas digitales E-Commerce, CRM y ERPs."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/seguridad-informatica/ciberseguridad/"/>
        <meta property="twitter:title" content="Empresa especialista en Ciberseguridad | Qualoom"/>
        <meta property="twitter:description" content="Expertos en Ciberseguridad, Análisis y Diagnóstico de Aplicaciones y Arquitecturas y Monitorización de ecosistemas digitales E-Commerce, CRM y ERPs."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/seguridad-informatica/ciberseguridad/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Seguridad informática", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/", "name": "Seguridad informática"}},
              {"@type":"ListItem", "position":"3", "name":"¿Qué es la ciberseguridad?", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/ciberseguridad/", "name": "¿Qué es la ciberseguridad?"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Experto en Ciberseguridad</h1>
              <p>Es el conjunto de prácticas que tienen como objetivo proteger los dispositivos electrónicos, servidores, redes y datos de accesos y manipulaciones no autorizadas.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Por qué es importante la ciberseguridad en empresas?</h2><hr></hr>
          <div className="section-main">
            <div className="section-img cyber"></div>
            <div className="section-text">
              <p>Muchas empresas desconocen las graves consecuencias que pueden tener los fallos en sus protocolos de ciberseguridad, su importancia radica en evitar situaciones de riesgo como el robo de datos confidenciales, el bloqueo de los recursos tecnológicos o el fraude financiero.</p>
              <p>En Qualoom tendrás a tu disposición un equipo de expertos en análisis y diagnóstico de aplicaciones y plataformas para garantizar la seguridad de tus datos más sensibles.</p>
            </div>
          </div>
          
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main content">
          <h2>Qualoom, experto en Ciberseguridad</h2><hr></hr>
          <p>Visita nuestro apartado de partner y comprobarás por qué Qualoom es experto en Ciberseguridad, situándose en la liga de las primeras firmas nacionales e internacionales que ofrecen servicios de esta tipología.</p><br/>
          <h3>¿Qué servicios ofrece Qualoom?</h3><Servicios></Servicios><br/>
          <h3>Seguridad informática en remoto</h3>
          <p>Inmersos en ecosistemas Cloud, OnPremise o Híbridos y rompiendo con los esquemas tradicionales de infraestructura inhouse, la actual administración y gobierno de servicios se realiza casi completamente de manera remota, lo que conlleva tener en cuenta este factor para disponer de unas herramientas de conectividad remota que cumplan con los estándares de seguridad.</p><br/>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default Ciberseguridad
